import React, { useRef } from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';
import { WorkoutCellInnerProps } from './types';
import { WorkoutCellViewModel } from './WorkoutCellViewModel';
import { TextSMMedium, TextSMRegular } from 'styleguide/Texts';
import { ProfileImage } from 'components/ProfileImage';
import { View } from 'styleguide/View';
import { Card } from 'styleguide/Card';
import { Stats } from './components/Stats';
import { WorkoutActions } from '../WorkoutActions';
import { DividerLine } from 'styleguide/DividerLine';
import { WorkoutContent } from './components/WorkoutContent';
import { Comments } from './components/Comments';
import { CommentInput } from './components/CommentInput';
import { Link } from 'components/Link';
import { WorkoutSocialPreview } from 'components/WorkoutSocialPreview';
import { modal } from 'components/Modals/ModalManager';
import { Spacing } from 'styleguide/spacing';
import { LinkableText } from 'components/LinkableText';
import { FlexRow } from 'styleguide/Row';

const Container = styled(Card)`
  display: flex;
  flex-direction: column;
  margin-bottom: ${Spacing.md}px;
`;

const TopContainer = styled(View)`
  gap: ${Spacing.md}px;
  padding: ${Spacing.lg}px;
  padding-bottom: ${Spacing.md}px;
`;

const MiddleContainer = styled(View)`
  gap: ${Spacing.md}px;
  padding: ${Spacing.md}px ${Spacing.lg}px;
`;

const BottomContainer = styled(View)`
  gap: ${Spacing.md}px;
  padding: ${Spacing.lg}px;
  padding-top: ${Spacing.md}px;
`;

export interface WorkoutCellProps {
  vm: WorkoutCellViewModel;
  publicWorkout?: boolean;
}

export const WorkoutCell = observer(({ vm, publicWorkout }: WorkoutCellProps) => {
  return <WorkoutCellInner {...vm.data} publicWorkout={publicWorkout} />;
});

const WorkoutCellInner = (props: WorkoutCellInnerProps) => {
  const commentInput = useRef<HTMLTextAreaElement | null>(null);

  return (
    <Container>
      <TopContainer>
        <Link href={`/user/${props.username}`}>
          <FlexRow>
            <ProfileImage source={props.profileImage} diameter={48} />
            <View style={{ marginLeft: Spacing.md }}>
              <TextSMMedium>{props.username}</TextSMMedium>
              <TextSMRegular>{props.date}</TextSMRegular>
            </View>
          </FlexRow>
        </Link>

        <Link href={`/workout/${props.shortId || props.workoutId}`}>
          <View style={{ marginBottom: Spacing.md }}>
            <TextSMMedium>{props.title}</TextSMMedium>
            {props.description && <LinkableText enableMentions text={props.description} />}
          </View>
          <Stats stats={props.stats} />
        </Link>
      </TopContainer>

      <DividerLine />

      <MiddleContainer>
        <Link href={`/workout/${props.shortId || props.workoutId}`}>
          <WorkoutContent {...props.content} publicWorkout={props.publicWorkout} />
        </Link>
        <WorkoutSocialPreview
          likeCount={props.likeCount}
          likeImages={props.likeImages}
          commentCount={props.commentCount}
          onLikesClick={
            props.publicWorkout
              ? undefined
              : () => {
                  modal.showUserListModal({ mode: 'likes', workoutId: props.workoutId });
                }
          }
          onCommentsClick={
            props.publicWorkout
              ? undefined
              : () => {
                  modal.openCommentModal(props.workoutId);
                }
          }
        />
      </MiddleContainer>

      {!props.publicWorkout && (
        <View>
          <WorkoutActions
            isLiked={props.isLiked}
            onLike={props.onLike}
            onComment={() => {
              modal.openCommentModal(props.workoutId);
            }}
            onShare={props.onShare}
          />
          <BottomContainer>
            {!!props.comments.length && (
              <Comments
                comments={props.comments}
                workout={props.workout}
                onCommentDelete={props.onCommentDelete}
              />
            )}
            <CommentInput
              profilePic={props.currentUserImage}
              onSend={props.onCommentSend}
              inputRef={commentInput}
            />
          </BottomContainer>
        </View>
      )}
    </Container>
  );
};
